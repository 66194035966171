<template>
  <div>

    <!-- DEBUG ! -->
    <div v-if='false'>
      <button @click='fill'>fill</button>
      <div>answers: {{ currentResponses }}</div>
      <div>unanswered: {{ unansweredQuestions }}</div>
    </div>


    <!-- HERO! -->
    <section class="hero is-small full-bg">
      <div class="hero-body">
        <div class='container has-text-centered'>
          <!-- <div> -->
            <!-- <img class='hero-img' src="@/assets/logo.png"/> -->
          <!-- </div> -->
          <h1 class="title is-size-1 has-text-white">
            Professional Learning Diagnostic
          </h1>
          <h2 class="subtitle has-text-white">by Rivet Education</h2>
        </div>
      </div>
    </section>


    <!-- CONTENT -->
    <div class='section' v-if='submitted === false'>
      <div class='container is-max-desktop'>      

        <!-- TITLE -->
        <div class='block content'>
          <p>This diagnostic is designed to assess the quality of professional learning plans and systems that are grounded in the use of high-quality instructional materials.</p>
          <p>The diagnostic is divided into six sections to help you quickly assess your professional learning system and practices. We recommend that you choose one content area to complete this diagnostic against, given that professional learning plans may vary by subject area in your school or system. Consider only ELA, math, or science since these are the only content areas with vetted and rated high-quality instructional materials.</p>
          <p>When you submit your responses, you will receive a color-coded rating of green, yellow, or red for each of the six sections.</p>
          <br>
        </div>

      
        <!-- LOOP THROUGH DOMAINS -->
        <div v-for='(domain, d) in config' :key='"qd"+d' class='block pb-5'>
            
          <!-- domain title -->
          <div class='block'>
            <h3 class='is-size-3 has-text-600 has-text-info'>{{ domain.domainTitle }}</h3>
          </div>

          <!-- LOOP THROUGH QUESTIONS -->
          <Question v-for="(question, q) in domain.questions" :key='"q"+q' 
                    :question='question' 
                    :domainId='domain.domainId' 
                    :isError='errorMsg !== null & unansweredQuestions.includes(question.qId)' />
        
        </div> 
        

        <!-- 'SUBMIT BUTTON' -->
        <div v-if='submitted === false'>
          <div class='block'>
            <button class='button is-large is-danger' @click='submitClicked'>Submit</button>
          </div>
          
          <div class='block'>
            <p v-if='errorMsg !== null' class='has-text-danger'>{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </div>

    
  </div>


</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import Question from './Question.vue';


export default {
  name: 'Home',
  components: {
    Question,
  },
  data() {
    return {
      submitted: false,
      errorMsg: null,
    }
  },
  watch: {
    submitted() {
      if (this.submitted === true) {
        this.$router.push({ 
          name: "results", 
          params: {id: this.uuid} 
        })
      }
    }
  },
  computed: {
    ...mapState(['currentResponses', 'uuid', 'config']),
    ...mapGetters(['unansweredQuestions'])
  },
  methods: {
    ...mapMutations(['setCurrentResponses']),
    ...mapActions(['submitResponses']),
    async submitClicked() {
      if (this.unansweredQuestions.length === 0) {
        this.errorMsg = null;
        await this.submitResponses();
        console.log('submitted')
        this.submitted = true;
      } else {
        this.errorMsg = 'Please answer all questions. There are '  + this.unansweredQuestions.length + ' questions remaining.';
      }
    },
    fill() {
      const allQuestions = this.config.map(x => (x.questions.map(y => ({'qId': y.qId, 'opt': y.options})))).flat(1)
      let output = [];
      allQuestions.forEach(q => {
        const item = q.opt[Math.floor(Math.random()*q.opt.length)];
        const result = {
          'qId': q.qId,
          'val': item.val
        }
        output.push(result)
      })
      this.setCurrentResponses(output);
      this.submitClicked();
    }
  }
}
</script>

